@import '../../commons.scss';

$column-padding: 1%;

.AlbumList {
  h1 {
    margin-left: 0.4em;
  }

  .albums-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .album {
    cursor: pointer;
    padding: 1em;
    width: calc((calc(100vw - #{$player-width}) / 1) - 2em);

    .title {
      margin-top: 0.3em;
      text-align: center;
    }
    img {
      border-radius: 0.5em;
      margin: auto;
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
  @media (min-width: 768px) {
    .album {
      width: calc((calc(100vw - #{$player-width}) / 2) - 2em);
    }
  }
  @media (min-width: 992px) {
    .album {
      width: calc((calc(100vw - #{$player-width}) / 3) - 2em);
    }
  }
  @media (min-width: 1200px) {
    .album {
      width: calc(((calc(100vw - #{$player-width}) / 4) - 2em));
    }
  }

  .message {
    margin: 0 1em;
    padding: 0.6em;
    text-align: center;
    border: 1px solid #000;
    border-radius: 3px;
    margin-bottom: 0.7em;
  }
  .error {
    color: #d31414;
    border-color: #d31414;
  }
}
