$header-height: 5em;
$album-widget-height: 16em;
$background-color: lighten(#eaf1f6, 3);
$text-color: #4b4b4b;
$player-width: 24em;

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@mixin gradient-background {
  background: -moz-linear-gradient(-45deg,  #af1431 0%, #240488 100%);
  background: -webkit-linear-gradient(-45deg,  #af1431 0%, #240488 100%);
  background: linear-gradient(110deg,  #af1431 0%, #240488 100%);
}

@mixin gradient-background-gray {
  background: -moz-linear-gradient(-45deg,  #e1e1e1 0%, #c6c6c6 100%);
  background: -webkit-linear-gradient(-45deg,  #e1e1e1 0%, #c6c6c6 100%);
  background: linear-gradient(110deg,  #e1e1e1 0%, #c6c6c6 100%);
}

@mixin gradient-text-background {
  background: -webkit-linear-gradient(#a80d64, #d631ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff0090;
}
