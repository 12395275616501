@import '../commons.scss';
.AppHeader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  padding: 0 1em;
  height: $header-height;
  background-color: $background-color;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include gradient-background;

  h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1.2em;
  }

  .version {
    color: #fff;
    opacity: 0.5;
    transform: translate(1px, 8px);
  }
  .search-field {
    padding: 0 0.3em 0 1em;
    border-radius: 2em;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.1);

    input {
      color: #fff;
      font-weight: 200;
      padding: 0.6em 0;
      outline: none;
      background-color: transparent;
      font-size: 1em;
      border: none;
      &::placeholder {
        color: #fff;
        opacity: 0.4;
      }
    }
    button {
      cursor: pointer;
      padding: 0.6em;
      font-size: 1em;
      background-color: transparent;
      border: none;
      outline: none;
      color: rgba(255, 255, 255, 0.4);
      &:hover {
        color: #fff;
      }
    }
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 2em;
    }

    .search-field {
      margin-right: 2em;
      input {
        width: 15em;
      }
    }
  }

  .spinner {
    color: #fff;
    padding: 0 0.5em;
    opacity: 0.4;
  }
  .search-form {
    margin-left: auto;
  }
}
