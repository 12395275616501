@import '../../commons.scss';

$column-padding: 1.5%;

.SongsList {
  .table {
    display: flex;
    flex-direction: column;
  }
  .table-header {
    border-bottom: 3px solid darken($background-color, 3);
    .column {
      cursor: pointer;
      font-weight: bold;
    }
  }

  .table-body {
    flex: 1;
    overflow-y: scroll;
  }

  .column {
    padding: 0.8em $column-padding;
    display: inline-block;
  }

  .sort-icon {
    color: #930bc3;
  }

  .table-row {
    position: relative;
    transition: background-color 0.2s;
    .column {
      vertical-align: middle;
      padding: 1em $column-padding;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-bottom: 1px solid darken($background-color, 4);
    }

    .controls {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 25px;
      transition: opacity 0.2s;
    }

    &:hover {
      background-color: darken($background-color, 2);

      .controls {
        opacity: 1;
      }
    }

  }

  .title {
    width: 60% - ($column-padding * 2);
  }
  .album {
    display: none;
  }
  .artist {
    width: 20% - ($column-padding * 2);
  }
  .duration {
    width: 20% - ($column-padding * 2);
  }

  .message {
    margin: 0 1em;
    padding: 0.6em;
    text-align: center;
    border: 1px solid #000;
    border-radius: 3px;
    margin-bottom: 0.7em;
  }
  .error {
    color: #d31414;
    border-color: #d31414;
  }
  .no-data {
    font-weight: bold;
    font-size: 1.4em;
    text-align: center;
    width: 100%;
    margin-top: 4em;
  }
  h1 {
    margin-left: 0.4em;
    display: block;
  }

  @media (min-width: 768px) {
    h1 {
      display: none;
    }

    .table {
      display: flex;
      flex-direction: column;
      height: calc(100vh - #{$header-height + $album-widget-height});
    }

    .title {
      width: 45% - ($column-padding * 2);
    }
    .album {
      display: inline-block;
      width: 20% - ($column-padding * 2);
    }
    .artist {
      width: 20% - ($column-padding * 2);
    }
    .duration {
      width: 15% - ($column-padding * 2);
    }
  }
}
