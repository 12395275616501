@import './commons.scss';

.App {
  .main {
  }
  .content {
    width: auto;
  }

  .album-widget {
    height: $album-widget-height;
  }

  .Player {
    padding-top: $header-height + 2em;
  }


  @media (min-width: 768px) {
    .main {
      padding-top: $header-height;
      height: calc(100vh - #{$header-height});
      display: flex;
    }

    .content {
      width: calc(100vw - #{$player-width});
    }

    .Player {
      position: fixed;
      right: 0;
      top: $header-height;
      width: $player-width;
      height: calc(100vh - #{$header-height});
      overflow-y: scroll;
      padding-top: 0;
    }
  }
}
.spinner {
  font-size: 2em;
  animation-name: spin;
  animation-duration: 400ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.visible {
  animation-name: fadein;
  animation-duration: 200ms;
  animation-timing-function: linear;
  opacity: 1;
}
.hidden {
  animation-name: fadeout;
  animation-duration: 200ms;
  animation-timing-function: linear;
  opacity: 0;
}

