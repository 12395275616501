@import '../../commons.scss';

.Player {
  background-color: darken($background-color, 2);

  .controls {
    height: 3em;
  }

  .thumbnail {
    height: 10em;
    width: 10em;
    margin: auto;
    border-radius: 1em;
    background-color: darken($background-color, 4);
    position: relative;
    border-radius: 30em;
    box-shadow: 0px 3px 40px darken($background-color, 15);
    border: 1em solid #fff;
    overflow: hidden;
      > div {
        width: 100%;
        height: 100%;
        background-position: center;
      }
    .icon {
      font-size: 3em;
      text-align: center;
      margin: auto;
      top: 34%;
      position: absolute;
      left: 34%;
    }
  }

  @media (min-width: 768px) {
    .thumbnail {
      margin-top: 2em;
    }
  }

  .control-icon {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    color: white;
    left: 63px;
    top: 63px;
    font-size: 2.4em;
  }

  .player-title {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
  }
}

.Playlist {
  .row {
    position: relative;
    cursor: pointer;
    padding: 0.5em 1em 0.5em 1.5em;
    transition: background-color 0.2s;
    border-bottom: 1px solid darken($background-color, 3);
    .column {
      display: inline-block;
      vertical-align: middle;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      background-color: darken($background-color, 4);
    }

    .current-song-indicator {
      position: absolute;
      font-size: 10px;
      left: 10px;
      top: 13px;
    }
  }

  .title {
    width: 85%;
  }
  .duration {
    width: 15%;
    text-align: right;
  }
}

.Timeline {
  margin: 1.3em 3em 1em 3em;
  .bar-background {
    cursor: pointer;
    margin: auto;
    background-color: darken($background-color, 8);
  }
  .bar {
    height: 3px;
    border-radius: 3em;
    @include gradient-background;

    &.background {
      @include gradient-background-gray;
      transform: translateY(2px);
    }
  }
}
